.home-button {
  width: 190px;
  height: 190px;
  vertical-align: center;
  font-size: 25px;
  margin-right: 10px;
  border-radius: 10px;
  border-color: white;
  //font-family: "Segoe UI Emoji";
  //font-weight: 600;
}

h2 {
  //color: #626161;
  margin: 50px;
  //padding-left: 50px;
}

h4 {
  font-weight: 600;
}

.news {
  color: red;
  margin: 50px;
  padding-left: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #e1f0e5;
  //font: 15px serif, sans-serif;

  //border: 2px dotted #b6c2b9;
  //border-radius: 5px;
}

.bg-newsletter {
  background: #b3d9ff;
  color: #0e0e0e;
}

.bg-website {
  background: #e6ffe6;
  color: #0e0e0e;
}

.bg-social-media {
  background: #ffb3b3;
  color: #0e0e0e;
}
