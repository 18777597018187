.prompts .p-button{
    color: black;
    background-color: rgba(80, 76, 129, 0.2);
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-weight: 400;
    border-style: none;

}

.prompts .p-button.p-highlight {
    font-weight: 500;
    background-color: rgb(80,76,129);
    color: rgb(249, 248, 255);
    border-style: none;

}

.hide {
     display: none;
     margin-left: 10px;
     font-size: 0.8em;
     font-weight: 400;
}

.copyMsg:hover + .hide {
     display: block;
     color: #ce1010;
}

#message-two {
    background-color: rgba(231, 246, 235, 0.84);
}

.card-prompts .p-card-content {
    display: table;
}


.prompts, .length {
    display: table-cell;
}
.length {
    padding-left:2%;


}
.length .p-dropdown {
    background-color: #504c81;
}
.length .p-dropdown-label {
    color: white !important;

}



div[hidden] {
    display: none;
}


.p-card-body .p-card-title {
    color: #000000;
    font-weight: 100;
    /*background: rgba(38, 80, 115, 0.2);*/
    border-bottom: solid;
    border-color: rgb(0, 0, 0);
    border-width: 1px;
}


/*Zusätzliche Anweisungen Button-----------------*/
.p-button.p-togglebutton.p-component{
    background: rgba(43, 112, 93, 0.65);
    padding: 10px;
    color: white;
    font-size:11px;
    height: 15px;
    width: 50px;
    margin: 2px;
    font-weight: 400;

}
.p-button.p-togglebutton.p-component.p-highlight{
    background-color: #504c81;
    color: white;
    font-size:11px;
    /*height: 15px;*/
    width: 50px;
    margin: 2px;
    font-weight: 400;

}
/*-----------------------------------------------------------------*/
/*Zusätzliche Einstellungen group-----------------*/
.add-sett-group {

    margin-left: 10px;
    margin-bottom: 10px;
}
/*-----------------------------------------------------------------*/

/*-----------------------------------------------------------------*/
/*Zusätzliche Einstellungen button-----------------*/
.add-sett-button {
    margin-left: 10px;
    margin-bottom: 5px;
}
/*-----------------------------------------------------------------*/


/*ChatGPT Versions Buttons---------------------*/

.model-buttons {
    /*margin-left: 10px;*/
}


.model-buttons .p-button{
    font-size:11px;
    background: none;
    font-weight: 400;
    color: black;
    /*border-color: none;*/
    /*border-width: 1px;*/


}
.model-buttons .p-button[aria-pressed="true"] {
    background: #504c81;
    font-weight: 700;
    color: white;
}
/*-----------------------------------------------------------------*/
/*ChatGPT Versions Info Icon---------------------*/
.version-info {
    color: green;
    margin: 5px;
    display: flex;

}
/*-----------------------------------------------------------------*/




/*Temperature elements-----------------*/
.temp-elements {
    /*border-style: groove;*/
    display: flex;
    margin-left: 30px;


}

.temp-label {
    display: flex;
    align-items: center;
    margin-right: 8px;

}

#temp-drop{
    background: none;
    /*color: #b40101;*/
    height: 30px;
    display: flex;
    align-items: center;
    /*font-size:11px;*/
}

#temp-drop .p-dropdown-trigger{
    background: none;
    color: #d70101;
    height: 30px;

    align-items: center;
    /*font-size:11px;*/
}










.p-dropdown-items-wrapper .p-dropdown-items{
    /*background: #bebecc;*/


}








/*-----------------------------------------------------------------*/

.add-sett-elements {
    display: flex;
    justify-content: center;
    align-items: center;
}

#toggle-button-sett {
    background: #504c81;
    color: white;
}

#toggle-button-sett[aria-pressed="true"]{
    background: rgba(43, 112, 93, 0.65);
    color:white;
}

/*.markdown-content code {*/
/*    background-color: rgba(190, 190, 155, 0.43); !* Light grey background *!*/
/*    color: black; !* Optionally, change the text color *!*/
/*    padding: 2px 4px;*/
/*    border-radius: 4px;*/
/*    font-family: 'Courier New', Courier, monospace; !* To make the font look more code-like *!*/
/*}*/
.markdown-content pre {
    background-color: black; /* Light grey background for code block */
    padding: 10px;
    border-radius: 5px;
    overflow-x: auto; /* Add scrolling if the code is too long */
    color: limegreen;
}

.markdown-content ol {
    margin-top: 0; /* Remove extra space at the top of the list */
    margin-bottom: 0; /* Remove extra space at the bottom of the list */
    padding-left: 20px; /* Adjust this to move the numbers closer to the text if needed */
    line-height: 1; /* Reduce space between list items */
}

.markdown-content ol li {
    margin-bottom: 0px; /* Reduce space between list items */
}

.markdown-content p {
    margin-bottom: 5px; /* Reduce space between paragraphs and lists */
}

.markdown-content h3 {
    margin-bottom: 0px;

}


/*.toggle-mine {*/
/*    background-color: pink;*/
/*    color: red;*/
/*}*/

/*.prompts .p-button:nth-child(1):hover {*/
/*    background-color: #ffffff !important; !* Red *!*/
/*    color: #ff6666 !important;*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*}*/

/*.prompts .p-button:nth-child(1) {*/
/*    color: rgb(203, 203, 224);*/
/*    background-color: rgb(80,76,129,0.5);*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/


/*.prompts .p-button:nth-child(2) {*/
/*    color: rgb(203, 203, 224);*/
/*    background-color: rgb(80,76,129,0.5);*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/

/*.prompts .p-button:nth-child(3) {*/
/*    color: rgb(203, 203, 224);*/
/*    background-color: rgb(80,76,129,0.5);*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/

/*.prompts .p-button:nth-child(4) {*/
/*    color: rgb(203, 203, 224);*/
/*    background-color: rgb(80,76,129,0.5);*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/

/*.prompts .p-button:nth-child(5) {*/
/*    color: rgb(203, 203, 224);*/
/*    background-color: rgb(80,76,129,0.5);*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/

/*}*/

/*.prompts .p-button:nth-child(6) {*/
/*    color: rgb(203, 203, 224);*/
/*    background-color: rgb(80,76,129,0.5);*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/

/*.prompts .p-button:nth-child(7) {*/
/*    background-color: #45d288; !* Red *!*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/

/*.prompts .p-button:nth-child(8) {*/
/*    background-color: #2bc4d0; !* Green *!*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/

/*.prompts .p-button:nth-child(9) {*/
/*    background-color: #b33cd9; !* Blue *!*/
/*    height: 3pc;*/
/*    text-align: center;*/
/*    display: block;*/
/*    width: 100%;*/
/*    margin: 10px;*/
/*}*/