.App {
  text-align: center;
}

.distance {
  padding: 25px;
}

.distance-content {
  padding: 55px;
}

.card-background {
  background-color: #e4effc;
}

.p-card .p-card-title {
  color: #3361ae;
}

.toolbar-background {
  background: none;
}



