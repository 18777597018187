

.header-metrics {
    text-align: center;
    font-weight: 400;
    margin-bottom: 50px;
}

/*.card-metrics{*/
/*    background: white;*/
/*    margin: 50px 50px auto;*/
/*}*/



/*.acordian {*/
/*    color: rgb(206, 16, 16);*/
/*}*/

/*.body {*/
/*    background: rgb(206, 16, 16);*/
/*}*/


.card-metrics {
    /*background: white;*/
    margin: 50px auto;
    max-width: 80%; /* Limit the maximum width of the card */
    overflow: hidden; /* Hide any content that overflows the card */
}

.log-file {
    max-height: 600px; /* Limit the maximum height of the log file section */
    overflow-y: auto; /* Add vertical scroll if content exceeds the height */
}

.acordian {
    /*color: rgb(206, 16, 16);*/
    white-space: pre-wrap; /* Preserve white spaces and wrap text */
}

.p-accordion-header-link{
    background-color: rgba(146, 199, 184, 0.15) !important;

}